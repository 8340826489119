/* Általános konténer */
.slimmer-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 15px;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Címek */
  .slimmer-title {
    text-align: center;
    color: #333;
    font-size: 22px;
    margin-bottom: 15px;
  }
  
  .slimmer-subtitle {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
  }
  
  /* Kártya stílus */
  .slimmer-card {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  /* Input mezők */
  .slimmer-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .slimmer-input {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Gomb stílus */
  .slimmer-btn {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .slimmer-btn:hover {
    background: #0056b3;
  }
  
  /* Hibaüzenet */
  .slimmer-error {
    color: red;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Táblázat konténer */
  .slimmer-table-container {
    overflow-x: auto;
  }
  
  /* Táblázat stílus */
  .slimmer-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .slimmer-table th, .slimmer-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .slimmer-table th {
    background: #f4f4f4;
  }
  
  /* Mobilbarát dizájn */
  @media (max-width: 480px) {
    .slimmer-container {
      max-width: 100%;
      padding: 10px;
    }
  
    .slimmer-table th, .slimmer-table td {
      padding: 8px;
      font-size: 14px;
    }
  }

  /* Felhasználónkénti bejegyzések lista */
.slimmer-user-list {
    list-style: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .slimmer-user-item {
    background: #e9ecef;
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 16px;
  }