.statRow {
    border: 1px solid white;
    border-radius: 25px;
    padding: 10px;
    flex-wrap: wrap;
    margin: 15px;
    box-shadow: -1px 7px 31px 0px rgb(0 0 0 / 45%);
    display: inline-flex;
}
.statUserRow {
    border: 1px solid white;
    border-radius: 25px;
    padding: 10px;
    margin: 15px;
    box-shadow: -1px 7px 31px 0px rgb(0 0 0 / 45%);
    display: inline-flex;
    width: 250px;
}
.statPhoneRow {
    border: 1px solid white;
        border-radius: 5px;
        padding: 10px;
        margin: 15px;
        box-shadow: -1px 7px 31px 0px rgb(0 0 0 / 45%);
        display: inline-flex;
        width: 150px;
}
p.paymentQty.resultlabel {
    border: 2px solid;
    border-radius: 20px;
    font-weight: 800;
    display: inline;
    padding: 5px;
}
.paymentDiv{
    margin:auto;
}
.titleRow {
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px;
    margin: 15px;
    box-shadow: -1px 7px 31px 0px rgb(0 0 0 / 45%);
    margin: auto;
    margin-bottom: 15px;
}
.paymentType {
    font-size: 13px;
        border: 1px solid gray;
        padding: 3px;
        border-radius: 5px;
        margin-top: 2px;
        margin-bottom: 5px;
}
.pay {
    margin-bottom: 5px;
    margin-left: 3px;
}
.paymentQty {
    font-size: 12px;
    padding-top:8px;
}
.scrapLabel {
    position: relative;
    top: -15px;
    font-size: 12px;
    background: white;
    width: 150px;
    margin: auto;
    color: black;
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.centerDiv {
    text-align: center;
}
.productAll {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}
.popup-content {
    height: 80%;
    border-radius: 10px;
    color: #282c34;
    padding:20px!important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 70%!important;
    overflow: auto;
}
.statTitle {
    font-size: 16px;
}
.scrapDiv{
    display: inline-block;
    text-align: center;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid gray;
}
.nagyDiv{
    text-align: center;
}
.paymentDiv1{
    width: 100%;
}