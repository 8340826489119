/* Attende.css */
.container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  h1 {
    font-size: 1.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Regisztrációs gomb */
  .register-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #0056b3;
  }
  
  /* Modális ablak */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* QR kód olvasó cím */
  .modal-content h2 {
    font-size: 1.2rem;
    color: #333;
  }
  
  /* Felugró ablak a regisztráció válaszával */
  .response-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    text-align: center;
  }
  
  .response-popup p {
    margin-bottom: 20px;
    color: #333;
  }
  
  .response-popup button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .response-popup button:hover {
    background-color: #0056b3;
  }